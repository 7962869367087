import {Decimal} from 'decimal.js';

import {Duration, Timestamp} from '@santa-web/gen/ssp/messages/google/protobuf';
import {Nullishable} from '@app/utils/type';

export const dateToTimestamp = (date: Date): Timestamp => {
  const value = date.valueOf();
  return {
    seconds: new Decimal(value).div(1000).floor().toString(),
    nanos: (value % 1000) * 10 ** 6,
  };
};
export const nullishableDateToTimestamp = (date: Nullishable<Date>) => date && dateToTimestamp(date);

export const timestampToMs = (timestamp: Timestamp | undefined | null): number => {
  if (!timestamp) return 0;
  return (timestamp.seconds ? +timestamp.seconds : 0) * 1000 + Math.floor((timestamp.nanos ?? 0) / 1000000);
};

export const nullishableTimestampToDate = (ts: Nullishable<Timestamp>) => {
  return ts && new Date(timestampToMs(ts));
};

export const generateProtobufDuration = (seconds: number, nanos: number): Duration => ({
  seconds: String(seconds),
  nanos,
});
