import {useRouter} from 'next/router';
import React from 'react';

import LoggedInUserOnlyPage from './components/guards/LoggedInUserOnlyPage';

const IndexContainer = () => {
  const router = useRouter();

  React.useEffect(() => {
    router.replace('/course/now');
  }, [router]);

  return null;
};

const IndexPage = () => {
  return (
    <LoggedInUserOnlyPage>
      <IndexContainer />
    </LoggedInUserOnlyPage>
  );
};

export default IndexPage;
